"use client";

import { Spinner } from "@material-tailwind/react";

import {useEffect, useRef, useState} from "react";
import {AuthenticationLoggedInEvent, eventBus, PmStore} from "@/state";
import {gql, useLazyQuery} from "@apollo/client";

export function OrganizationToggle() {
  const [Loading, setLoading] = useState(true);
  const state = PmStore();



  const isAuthenticatedRef = useRef(PmStore.getState().isAuthenticated);
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => PmStore.subscribe(
      (state) => (isAuthenticatedRef.current = state.isAuthenticated)
  ), [])




  const selectedOrgRef = useRef(PmStore.getState().selectedOrganization);
  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => PmStore.subscribe(
      (state) => (selectedOrgRef.current = state.selectedOrganization)
  ), [])




  const gqlCurrentOrganization = gql`
    query currentOrganization {
      currentOrganization {
       id
       name
      }
    }
  `;

  const [apiGetCurrentOrganizationId] = useLazyQuery(gqlCurrentOrganization);


  function GetOrganizations() {
    apiGetCurrentOrganizationId().then((result) => {
      let data = result?.data?.currentOrganization;
      console.log(data)
        state.setSelectedOrg(data);
        setLoading(false)




      // state.setKnownOrganizations(data);
    });
  }



  useEffect(() => {
    GetOrganizations();
  },[])








  eventBus.subscribe((event) => {
    switch (event.type) {
      case AuthenticationLoggedInEvent:
        //nothing for now
        break;
      default:
        break;
    }
  });

  if (Loading) {
    return (
      <div className={" max-w-100 pt-2"}>
        <Spinner />

      </div>
    );
  }

  return (
    <div className={" max-w-100 pt-2"}>
      {selectedOrgRef?.current?.name}
    </div>
  );
}
