"use client";
import type { Metadata } from "next";

import { Inter } from "next/font/google";
import "./globals.css";
import TopNavigationBar from "@/components/topnav/TopNavigationBar";
import {ApolloWrapper} from "@/app/ApolloWrapper";
import React from "react";
import {Toaster} from "react-hot-toast";


const inter = Inter({ subsets: ["latin"] });

// export const metadata: Metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };
//
//



export default function RootLayout({
  children,
}: Readonly<{ children: React.ReactNode;}>) {
  return (
      <html lang="en">
      <head>
          <script type="text/javascript" src="https://js.moov.io/v1"></script>
      </head>


      <body className={inter.className}>
      <ApolloWrapper>
          <Toaster/>
          <TopNavigationBar/>
          <div className={"p-1"}>
              {children}
          </div>

      </ApolloWrapper>
      </body>
      </html>
  );
}
