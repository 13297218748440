"use client";
// ^ this file needs the "use client" pragma

import {ApolloLink, createHttpLink, HttpLink, useMutation} from "@apollo/client";
import {
    ApolloNextAppProvider,
    ApolloClient,
    InMemoryCache,
    SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";
import React, {useEffect, useState} from "react";
import {setContext} from "@apollo/client/link/context";
import {PmStore} from "@/state/store";




// you need to create a component to wrap your app in
export function ApolloWrapper({ children }: Readonly<{ children: React.ReactNode;}>) {



    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = PmStore.getState().token;

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            },
        };
    });


    // have a function to create a client for you
    function makeApolloClient() {

        let apiUrl = "/api/graphql/";
        if (typeof window !== "undefined") {
            apiUrl = window?.location?.hostname === "localhost" ? "https://rentalflow.dwai.xyz/api/graphql/":"/api/graphql/";
        }


        const httpLink = createHttpLink({
                    uri:  apiUrl,
                });

        // use the `ApolloClient` from "@apollo/experimental-nextjs-app-support"
        return new ApolloClient({
            // use the `InMemoryCache` from "@apollo/experimental-nextjs-app-support"
            cache: new InMemoryCache(),
            link: authLink.concat(httpLink),
        });


    }

    return (
        <ApolloNextAppProvider makeClient={makeApolloClient}>
            {children}
        </ApolloNextAppProvider>
    );
}